import {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouterLink,
} from "vue-router";
import { msalInstance, loginRequest } from "../authConfig";
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import store from "@/store/index";
import { checkTokenFromFCH } from "@/requests/checkTokenFromFCH";
import useSSOFunction from "@/composables/composableGestioneSSO";
const { statusFunction, puoNavigare, logoutFunction } = useSSOFunction();

export function registerGuard(router: Router) {
  const accounts = msalInstance.getAllAccounts();

  router.beforeEach(
    async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const pagineAutorizzate = store.getters.pagineAutorizzate;
      const autorizzatoVisitarePagina = pagineAutorizzate.some((pagina) => {
        // const percorsoSenzaId = percorso.replace(/\/:[^/]+/g, "");
        return pagina
          .replace(/\/:[^/]+/g, "")
          .startsWith(`/${to.fullPath.split("/")[1]}`);
      });
      if (
        !to.fullPath.includes(process.env.VUE_APP_TEXT ?? "") &&
        !autorizzatoVisitarePagina &&
        pagineAutorizzate.length &&
        to.fullPath != "/404" &&
        to.fullPath != "/manutenzione" &&
        to.fullPath != "/non-autorizzato"
      ) {
        return "/non-autorizzato";
      }
      // inserire pagine tipo 404 manutenzione ecc

      if (to.fullPath === "/logout" || to.fullPath === "/logout-post")
        return true;
      if (to.query.FCHT) {
        const puoEntrareUtenteFCHStatus = await checkTokenFromFCH(
          to.query.FCHT
        );
        localStorage.setItem("utenteFCH", "1");
        localStorage.setItem("FCHT", to.query.FCHT as string);
        localStorage.setItem(
          "puoEntrareUtenteFCHStatus",
          puoEntrareUtenteFCHStatus.toString()
        );
      }
      if (to.hash.includes("AADB2C90118") || to.hash.includes("AADB2C90091")) {
        store.commit("setIsLoggingAdb2c");
      }
      if (!accounts.length && to.meta.noAuth === true) {
        return true;
      } else {
        await statusFunction();

        if (to.fullPath === "/sign-in") return "/";

        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );

        return shouldProceed && puoNavigare.value;
      }
    }
  );
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not

  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      return false;
    })
    .catch((e) => {
      logoutFunction();
      // instance.logoutRedirect();
      return false;
    });
}
